// Primary Interface Colors
$primaryBlue: #033a6d; // Dark blue for primary branding
$secondaryBlue: #2370a3; // Secondary branding blue
$accentOrange: #f9a825; // Bright orange for CTA buttons or highlights
$grayLight: #e6e9f0; // General light gray used for card borders, lines, and disabled labels

// Background Colors
$backgroundLight: #f5f6f8; // Lightest blue for backgrounds
$backgroundDisabled: #f0f2f5; // Very light gray for disabled input fields
$backgroundCard: #e7f8fd; // Light blue for card headers
$darkBlueBackground: #052144; // Very dark blue, used for subscribe banners and impactful backgrounds
$lightGreyBackground: #faf9f8;

// Functional Colors
$error: #ff3b3b; // Bright red for errors
$errorDark: #f44336; // Dark red used for error texts
$errorLight: #fbf3f2; // Very light pink for error backgrounds
$warning: #bf2c2c; // Dark red for warnings
$warningLight: #ff9f2a; // Bright orange for less severe warnings
$warningBackground: #fff8e3; // Light yellow for warning backgrounds
$success: #43a047; // Green for success messages
$successLight: #e6f9f4; // Very light green for success backgrounds
$orangeLight: #ff9250; // Bright orange, used for less critical alerts and interactive elements
$warningYellow: #aa6000; // Dark yellow, used for warning messages and caution indicators
$grayExtraLight: #f0f5f8; // Very light gray, used for subtle backgrounds, such as under information blocks or secondary interface elements

// Text Colors
$info: #00c8ff; // Bright cyan for informational messages
$textPrimary: #042238; // Darkest blue for primary text
$textSecondary: #333f4d; // Dark blue for secondary text
$textTertiary: #445163; // Gray blue for tertiary text
$textLight: #516a80; // Light blue for less prominent text
$textPale: #6d757f; // Light gray for less important text
$textGrayLight: #acb9c8;

// Special Use Colors
$profileBlue: #5da7bc; // Soft blue for profile-related UI elements
$listBackground: #627186; // Gray blue for list backgrounds or count badges
$labelMarketingYellow: #f0dfb1; // Pale yellow for marketing labels
$progressGreen: #009e74; // Green for progress bars

// Alert Colors
$alertHigh: #ea4d45; // Red for high risk alerts
$alertMedium: #f8cd46; // Yellow for medium risk alerts
$alertLow: #5abc91; // Green for low risk alerts
$successGreen: #00805f; // Dark green, used for success states and positive indicators

// Brands and Social Media Colors
$facebookBlue: #1877f2; // Bright blue for Facebook-related buttons
$googleBlue: #4285f4; // Slightly lighter blue for Google-related buttons
$redfinRed: #8d2823; // Redfin red for branding
$reiHubPurple: #431c5d; // Dark purple for REI Hub branding

// Miscellaneous
$highlightYellow: #ffcd00; // Bright yellow used for highlighting important elements
$lightGreenBright: #04bf8d; // Bright green for alternative interactive elements
$iceBlue: #c9edff; // Light blue for cold or less emphatic elements
$iceBlueAlt: #dbe7ff; // Very light blue for alternative cold elements
$grayScale: #d8d8d8; // Light gray for borders or subtle backgrounds
$pink: #e91e63; // Bright pink, used for attention-grabbing elements or promotional highlights
$lightCardHeaderBackground: #f5f8fe; // Very light blue, used for card headers and sections
$modalLightBlueBackground: #f5f9ff; // Lightest blue, used for modal backgrounds to promote focus
$helpCardBackground: #a9f3df; // Very light mint green, used for refreshing or calm elements
$sureBlue: #022c52; // Slightly lighter than $darkBlueBackground, used for formal or serious contexts
$lightRedBackground: #feefef; // Very light red, used for background highlighting in error states
$darkGray: #687a88; // Dark gray, used for less emphasized text or subdued UI elements
$blueTag: #7fe3ff; // Light blue, used for tags and markers
$hoverGray: #d9e1e9; // Light gray, used for hover states or subtle backgrounds
$steelBlue: #4f7599; // Steel Blue, used for hover state for tabs on Premium Modal
$errorGray: #9ba7af; // Light Gray, used for error filled icon in ComparisonChart

// Standard Colors
$white: #ffffff; // White used for pure background
$black: #21282a; // Black used for text or deep shadows

$title-font: Poppins, sans-serif;

@mixin title-extra-bold {
  font-weight: 800;
  font-family: $title-font;
}

@mixin title-bold {
  font-weight: bold;
  font-family: $title-font;
}

@mixin title-semi-bold {
  font-weight: 600;
  font-family: $title-font;
}

@mixin title-medium {
  font-weight: 500;
  font-family: $title-font;
}

@mixin title-regular {
  font-weight: normal;
  font-family: $title-font;
}

$smallMobileBreakpoint: 320px;
$mobileBreakpoint: 479px;
$smallScreenWidth: 768px;
$largeScreenBreakpoint: 969px;
$tabsLayoutBreakpoint: 1200px;
$tabletBreakpoint: 1024px;
$bigScreenBreakpoint: 1400px;

$breakpoints: (
  smallMobileBreakpoint: $smallMobileBreakpoint,
  mobileBreakpoint: $mobileBreakpoint,
  largeScreenBreakpoint: $largeScreenBreakpoint,
  smallScreenWidth: $smallScreenWidth,
  tabsLayoutBreakpoint: $tabsLayoutBreakpoint,
  tabletBreakpoint: $tabletBreakpoint,
  bigScreenBreakpoint: $bigScreenBreakpoint,
);

// This mixin will handle larger than breakpoints
// @include media-breakpoint-up(smallMobileBreakpoint) {}
// mq-up > media breakpoint up
@mixin mq-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle smaller than breakpoints
// @include media-breakpoint-down(bigScreenBreakpoint) {}
// mq-down > media breakpoint down
@mixin mq-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle sizez between breakpoints you choose
// @include media-breakpoint-between(mobileBreakpoint, smallScreenWidth) {}
// mq-between > media breakpoint between
@mixin mq-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @if ($lower-breakpoint > $upper-breakpoint) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower} > #{$upper}!";
    }

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  } @else {
    // If custom values
    @if ($lower > $upper) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower}px > #{$upper}px!";
    }

    @media (min-width: $lower) and (max-width: ($upper)) {
      @content;
    }
  }
}

%skeleton-animation {
  background: linear-gradient(-90deg, #efefef 0%, #e6e9f0 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &-dark {
    background: linear-gradient(-90deg, #042e53 0%, #033a6d 50%, #042e53 100%);
  }
}

%transition {
  transition: all 300ms ease-in;

  &Slow {
    transition: all 400ms ease-in;
  }
}

@keyframes clicked {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.container {
  color: white;

  .mainContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: #033a6d;

    .parallax,
    .blueBg {
      padding-left: 64px;
      padding-right: 64px;

      @include mq-down(mobileBreakpoint) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .whyTurbotenant,
    .stateSpecificLeaseAgreements,
    .footer {
      max-width: 1130px;
    }

    .parallax {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-top: 48px;
      background:
        linear-gradient(180deg, #042238 80.47%, #033a6d 100%) 0 0 / 100% 95vh
          no-repeat,
        #033a6d;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include mq-down(mobileBreakpoint) {
        padding: 24px 16px 32px 16px;
      }
    }

    .blueBg {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .placeholder {
        height: 170px;
      }
      transform: translateY(-170px);
      position: relative;
      padding-bottom: 32px;

      .marginSubstractor {
        margin-top: -170px;
      }
    }

    .blueBg > * {
      position: relative;
      z-index: 1;
    }

    .blueBg::before {
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        rgba(3, 58, 109, 0) 0%,
        rgba(3, 58, 109, 1) 15%,
        rgba(3, 58, 109, 1) 100%
      );

      @include mq-down(mobileBreakpoint) {
        background: linear-gradient(
          rgba(3, 58, 109, 0) 0%,
          rgba(3, 58, 109, 1) 10%,
          rgba(3, 58, 109, 1) 100%
        );
      }
    }

    .results {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1130px;

      .titleSection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        margin-bottom: 16px;
        max-width: 750px;

        .sparkles {
          transform: translateY(5px);
          margin-right: 8px;
        }

        h2 {
          margin: 0;
          font-size: 16px;
          text-transform: uppercase;
        }

        p {
          font-style: italic;
          color: white;
          margin: 0 0 24px;
        }
      }

      .cols {
        display: flex;
        width: 100%;
        margin-bottom: 64px;

        @include mq-down(650px) {
          flex-direction: column;
          margin-bottom: 0;
        }
      }

      .colRight {
        .stickyWrapper {
          position: sticky;
          top: 32px;
          z-index: 10;
        }

        .overwhelmedCard {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px;
          color: white;
          text-align: center;
          background-color: #1c4e7c;
          border-radius: 4px;
          margin-bottom: 16px;

          @include mq-down(650px) {
            width: 100%;
            margin-top: 32px;
          }

          @include mq-down(mobileBreakpoint) {
            display: none;
          }

          .owTitle {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 8px;
          }

          .owDescription {
            margin-bottom: 32px;
            color: white;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          .useBuilderButton {
            width: fit-content;
            padding: 20px 26px;
            background-color: $info;
            color: $textPrimary;

            &:hover {
              background-color: $blueTag;
              color: $primaryBlue;
            }
          }
        }
      }

      .colLeft {
        margin-right: 32px;
        width: 100%;
        z-index: 1;

        .dropdowns {
          margin-bottom: 16px;
        }

        .btnWrapper {
          display: flex;
          justify-content: flex-end;

          @include mq-down(smallScreenWidth) {
            width: 100%;
          }

          .sendBtn {
            margin-top: 12px;
            max-width: 250px;
            color: white;
            border-color: white;
            padding: 0 24px;

            &.loading {
              cursor: not-allowed;
              color: $white;
              border-color: $white;

              > div > * {
                background-color: white;
              }
            }

            &:hover {
              background-color: white;
              color: $primaryBlue;
              border-color: $textPrimary;
            }

            @include mq-down(smallScreenWidth) {
              width: 100%;
              max-width: initial;
            }
          }
        }

        .feedbackLabel {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: white;
          margin-bottom: 8px;

          span {
            color: $textGrayLight;
          }
        }

        .howDoYouLike {
          display: flex;
          flex-direction: column;
          font-weight: 600;
          font-size: 18px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 14px 16px;
          cursor: pointer;
          background-color: $sureBlue;
          border: 1px solid $primaryBlue;
          border-radius: 4px;

          &.feedbackSent {
            align-items: center;
            justify-content: center;

            .title {
              font-size: 24px;
              transition: none;
            }

            .feedbackLabel {
              font-size: 14px;

              @include mq-down(smallScreenWidth) {
                text-align: center;
              }
            }
          }

          &.clicked {
            .title {
              max-width: 0;
              opacity: 0;

              @include mq-down(smallScreenWidth) {
                display: none;
              }
            }

            .thumbs {
              flex-direction: row;
            }

            .feedbackBottom {
              transition-delay: 700ms;
              opacity: 1;
              max-height: 400px;
            }

            .thumbsUp,
            .thumbsDown {
              opacity: 0.5;

              &.selected {
                opacity: 1;
                animation: clicked 300ms ease-in;
                animation-delay: 300ms;
              }
            }
          }

          .title {
            max-width: 100%;
            flex-shrink: 0;
            overflow: hidden;
            margin-right: 8px;
            max-height: 40px;
            text-wrap: nowrap;
            @extend %transition;

            @include mq-down(smallScreenWidth) {
              text-wrap: wrap;
              max-height: initial;
              flex: 1;
              text-align: center;
              margin-bottom: 8px;
            }
          }

          @include mq-down(mobileBreakpoint) {
            flex-direction: column;
          }

          .thumbs {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 8px;

            @include mq-down(smallScreenWidth) {
              flex-direction: column;
            }

            @include mq-down(mobileBreakpoint) {
              padding-top: 12px;
            }

            .thumbs {
              &Up {
                display: flex;
              }

              &Down {
                display: flex;
              }
            }

            svg {
              width: 46px;
              height: 46px;
              color: $blueTag;

              &:first-child {
                margin-right: 8px;
              }
            }
          }

          .feedbackBottom {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            @extend %transitionSlow;

            .textarea {
              color: $textPrimary;
              padding: 8px 0 0 12px;
              width: 100%;
              font-size: 16px;
              border-radius: 4px;

              @include mq-down(smallScreenWidth) {
                padding: 8px;
              }

              &:focus {
                outline: none;
              }

              &::placeholder {
                color: $textPrimary;
                font-weight: 400;
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }

  .titleSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      color: $grayLight;
    }

    svg {
      fill: $grayLight;
    }

    h4 {
      text-transform: uppercase;
      font-size: 16px;
      margin: 0;
    }
    h3 {
      font-size: 32px;
      margin: 0;
      margin-bottom: 8px;

      span {
        color: $info;
        font-family: Poppins;
      }
    }
  }

  .whyTurbotenant {
    padding-top: 64px;
    padding-bottom: 64px;

    @include mq-down(mobileBreakpoint) {
      padding-top: 48px;
      padding-bottom: 32px;
    }

    .watchHow {
      display: flex;
      align-items: center;
      color: $blueTag;
      font-weight: 600;
      margin-top: 8px;
      cursor: pointer;

      &:hover,
      &:hover * {
        color: $info;
        fill: $info;
      }

      .playButton {
        margin-left: 8px;
      }
    }
  }

  .stateSpecificLeaseAgreements {
    display: flex;
    align-items: center;
    margin-bottom: 64px;

    @include mq-down(mobileBreakpoint) {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 32px;
    }

    .col1 {
      margin-right: 32px;

      @include mq-down(mobileBreakpoint) {
        margin-right: 0;
      }

      .titleSection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        color: white;

        @include mq-down(mobileBreakpoint) {
          align-items: center;
          text-align: center;
        }

        p {
          color: white;
          margin-top: 0;
          margin-bottom: 32px;
        }

        .buildMyLeaseButton {
          color: white;
          border-color: white;
          width: fit-content;
          padding-left: 32px;
          padding-right: 32px;

          &:hover {
            background-color: white;
            color: $primaryBlue;
          }
        }
      }
    }

    .col2 {
      @include mq-down(mobileBreakpoint) {
        width: 100%;
      }

      .buildLeaseImage {
        width: 550px;
        height: 430px;
        position: relative;

        @include mq-down(mobileBreakpoint) {
          display: flex;
          justify-content: center;
          width: 100%;
          height: auto;
          margin-bottom: 32px;
        }
      }
    }
  }

  .footer {
    a {
      color: $info;
      font-weight: 600;
      color: $blueTag;

      &:hover {
        color: $info;
      }
    }
    svg {
      fill: white;
    }
  }

  .shareAudit {
    color: $white;
    font-size: 16px;

    &:hover {
      opacity: 0.8;
    }

    &.desktop {
      margin: 0 auto;

      @include mq-down(mobileBreakpoint) {
        display: none;
      }
    }

    &.mobile {
      margin-left: auto;

      @include mq-up(mobileBreakpoint) {
        display: none;
      }
    }

    .shareAuditIcon {
      fill: $white;
      width: 20px;
      height: 20px;
    }
  }
}
