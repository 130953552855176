// Primary Interface Colors
$primaryBlue: #033a6d; // Dark blue for primary branding
$secondaryBlue: #2370a3; // Secondary branding blue
$accentOrange: #f9a825; // Bright orange for CTA buttons or highlights
$grayLight: #e6e9f0; // General light gray used for card borders, lines, and disabled labels

// Background Colors
$backgroundLight: #f5f6f8; // Lightest blue for backgrounds
$backgroundDisabled: #f0f2f5; // Very light gray for disabled input fields
$backgroundCard: #e7f8fd; // Light blue for card headers
$darkBlueBackground: #052144; // Very dark blue, used for subscribe banners and impactful backgrounds
$lightGreyBackground: #faf9f8;

// Functional Colors
$error: #ff3b3b; // Bright red for errors
$errorDark: #f44336; // Dark red used for error texts
$errorLight: #fbf3f2; // Very light pink for error backgrounds
$warning: #bf2c2c; // Dark red for warnings
$warningLight: #ff9f2a; // Bright orange for less severe warnings
$warningBackground: #fff8e3; // Light yellow for warning backgrounds
$success: #43a047; // Green for success messages
$successLight: #e6f9f4; // Very light green for success backgrounds
$orangeLight: #ff9250; // Bright orange, used for less critical alerts and interactive elements
$warningYellow: #aa6000; // Dark yellow, used for warning messages and caution indicators
$grayExtraLight: #f0f5f8; // Very light gray, used for subtle backgrounds, such as under information blocks or secondary interface elements

// Text Colors
$info: #00c8ff; // Bright cyan for informational messages
$textPrimary: #042238; // Darkest blue for primary text
$textSecondary: #333f4d; // Dark blue for secondary text
$textTertiary: #445163; // Gray blue for tertiary text
$textLight: #516a80; // Light blue for less prominent text
$textPale: #6d757f; // Light gray for less important text
$textGrayLight: #acb9c8;

// Special Use Colors
$profileBlue: #5da7bc; // Soft blue for profile-related UI elements
$listBackground: #627186; // Gray blue for list backgrounds or count badges
$labelMarketingYellow: #f0dfb1; // Pale yellow for marketing labels
$progressGreen: #009e74; // Green for progress bars

// Alert Colors
$alertHigh: #ea4d45; // Red for high risk alerts
$alertMedium: #f8cd46; // Yellow for medium risk alerts
$alertLow: #5abc91; // Green for low risk alerts
$successGreen: #00805f; // Dark green, used for success states and positive indicators

// Brands and Social Media Colors
$facebookBlue: #1877f2; // Bright blue for Facebook-related buttons
$googleBlue: #4285f4; // Slightly lighter blue for Google-related buttons
$redfinRed: #8d2823; // Redfin red for branding
$reiHubPurple: #431c5d; // Dark purple for REI Hub branding

// Miscellaneous
$highlightYellow: #ffcd00; // Bright yellow used for highlighting important elements
$lightGreenBright: #04bf8d; // Bright green for alternative interactive elements
$iceBlue: #c9edff; // Light blue for cold or less emphatic elements
$iceBlueAlt: #dbe7ff; // Very light blue for alternative cold elements
$grayScale: #d8d8d8; // Light gray for borders or subtle backgrounds
$pink: #e91e63; // Bright pink, used for attention-grabbing elements or promotional highlights
$lightCardHeaderBackground: #f5f8fe; // Very light blue, used for card headers and sections
$modalLightBlueBackground: #f5f9ff; // Lightest blue, used for modal backgrounds to promote focus
$helpCardBackground: #a9f3df; // Very light mint green, used for refreshing or calm elements
$sureBlue: #022c52; // Slightly lighter than $darkBlueBackground, used for formal or serious contexts
$lightRedBackground: #feefef; // Very light red, used for background highlighting in error states
$darkGray: #687a88; // Dark gray, used for less emphasized text or subdued UI elements
$blueTag: #7fe3ff; // Light blue, used for tags and markers
$hoverGray: #d9e1e9; // Light gray, used for hover states or subtle backgrounds
$steelBlue: #4f7599; // Steel Blue, used for hover state for tabs on Premium Modal
$errorGray: #9ba7af; // Light Gray, used for error filled icon in ComparisonChart

// Standard Colors
$white: #ffffff; // White used for pure background
$black: #21282a; // Black used for text or deep shadows

$title-font: Poppins, sans-serif;

@mixin title-extra-bold {
  font-weight: 800;
  font-family: $title-font;
}

@mixin title-bold {
  font-weight: bold;
  font-family: $title-font;
}

@mixin title-semi-bold {
  font-weight: 600;
  font-family: $title-font;
}

@mixin title-medium {
  font-weight: 500;
  font-family: $title-font;
}

@mixin title-regular {
  font-weight: normal;
  font-family: $title-font;
}

$smallMobileBreakpoint: 320px;
$mobileBreakpoint: 479px;
$smallScreenWidth: 768px;
$largeScreenBreakpoint: 969px;
$tabsLayoutBreakpoint: 1200px;
$tabletBreakpoint: 1024px;
$bigScreenBreakpoint: 1400px;

$breakpoints: (
  smallMobileBreakpoint: $smallMobileBreakpoint,
  mobileBreakpoint: $mobileBreakpoint,
  largeScreenBreakpoint: $largeScreenBreakpoint,
  smallScreenWidth: $smallScreenWidth,
  tabsLayoutBreakpoint: $tabsLayoutBreakpoint,
  tabletBreakpoint: $tabletBreakpoint,
  bigScreenBreakpoint: $bigScreenBreakpoint,
);

// This mixin will handle larger than breakpoints
// @include media-breakpoint-up(smallMobileBreakpoint) {}
// mq-up > media breakpoint up
@mixin mq-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle smaller than breakpoints
// @include media-breakpoint-down(bigScreenBreakpoint) {}
// mq-down > media breakpoint down
@mixin mq-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle sizez between breakpoints you choose
// @include media-breakpoint-between(mobileBreakpoint, smallScreenWidth) {}
// mq-between > media breakpoint between
@mixin mq-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @if ($lower-breakpoint > $upper-breakpoint) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower} > #{$upper}!";
    }

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  } @else {
    // If custom values
    @if ($lower > $upper) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower}px > #{$upper}px!";
    }

    @media (min-width: $lower) and (max-width: ($upper)) {
      @content;
    }
  }
}

%skeleton-animation {
  background: linear-gradient(-90deg, #efefef 0%, #e6e9f0 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &-dark {
    background: linear-gradient(-90deg, #042e53 0%, #033a6d 50%, #042e53 100%);
  }
}

/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  width: 300px;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  width: 100%;
  max-width: 300px;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-NavBar {
  display: none;
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 20px;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #516a80;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  width: 35px;
  height: 41px;
  font-weight: 600;
  font-size: 14px;
  color: #516a80;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #033a6d;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  position: relative;

  background-color: #033a6d;
  color: #f0f8ff;
  font-weight: bold;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--selected
  ):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 9999;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
